// inject username here

import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth'


import Milestone from './WelcomePage/milestone'
import milestones from './WelcomePage/milestones';

import finturoBubbles from '../img/FinturoBubbles.png'

const Welcome = () => {

    const { firstName, signUpStatus, isAdmin} = useAuth()
    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)

    const location = useLocation();
    const updatedSignUpStatus = location.state?.updatedSignUpStatus;

    const content = (
        <section className="welcome flex flex-col items-center justify-center">
            <div className="w-screen overflow-hidden">
      {!isAdmin && (
        <>
        {/* <p>Sign-Up Status:{updatedSignUpStatus ?? signUpStatus}</p> */}
        <div className="flex flex-col items-center p-5 justify-center">
            <div className=''>
              <img className='h-36 w-36 text-center m-6' src={finturoBubbles}></img>
            </div>
            <h1 className="text-center text-xl font-bold mb-2">Welcome to Finturo, your new automatic wealth generating account!</h1>
            <h3 className="text-center text-gray-600 mb-2">In just 3 steps you will begin funding and investing in your financial future!</h3>
            <Milestone items={milestones} signUpStatus={updatedSignUpStatus ?? signUpStatus} />
        </div> 
        </>  
      )}

      {isAdmin && (
        <div className="mt-8">
          <p className="text-gray-600 mb-2">{today}</p>
      <h1 className="text-4xl font-bold mb-4">Welcome, {firstName}!</h1>
          <p className="text-gray-800 mb-2 flex justify-center">
            <Link to="/dash/users">
              <button className="bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none">
                view users
              </button>
            </Link>
          </p>
          <p className="text-gray-800 flex justify-center">
            <Link to="/dash/users/new">
              <button className="bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none">
                add new user
              </button>
            </Link>
          </p>
        </div>
      )}
      </div>
    </section>
    
    )

    return content
}
export default Welcome