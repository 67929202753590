// Sign up section
import React, {useState, useEffect} from 'react'
import FidSignUp from '../FidSignUp'

import fidelityLogo from '../../img/fidelity-investments-logo-2.png';
import AccountType from './accountType';
import brokeText, {brokeTexto} from './accountText'; 
import DashHeader from '../../../DashHeader';

export default function FidelityRothIRA(){

      const language = localStorage.getItem('i18nextLng');

      return(
        <div className='md:pb-10'>
                <>
                {/* <DashHeader></DashHeader> */}
                <div className='px-4 md:px-40 flex flex-col pt-20 md:pt-28 '>
                    
                <AccountType items = {language === 'en' ? brokeText[2] : brokeText[3]}/>                 
                   <div className='pt-10 flex flex-col items-center'>
                        <h1 className='text-2xl text-center text-green-800 font-extrabold'> 2. Link Fidelity to Finturo </h1>
                        {/* <p className='text-sm text-center text-gray-600'> We will never share this information. </p> */}
                        <div className='pt-4'>
                            <FidSignUp></FidSignUp>
                        </div>
                    </div>
                </div>
                </>
        </div>
      );
}