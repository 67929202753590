const instruction = [
    {
        number: "1",
        text : "Log in & Access Accounts & Trade"
      },
      {
        number: "2",
        text: "Transfers"
      },
      {
        number: "3",
        text: 'Manage Bank Accounts' 
      },
      {
        number: "4",
        text: 'Link a New Bank Account' 
      },
]

export default instruction