// Sign up section
import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import FidSignUp from '../FidSignUp'
import AccountType from './accountType';
import brokeText, {brokeTexto} from './accountText'; 
import DashHeader from '../../../DashHeader';

export default function FidelityBrokerage(){

        const { t } = useTranslation()

        const language = localStorage.getItem('i18nextLng');
      return(
        
        <div className='pb-10'>
                <>
                <div className='px-8 flex flex-col pt-20 md:pt-28 items-center justify-center'>
                    <AccountType items = {language === 'en' ? brokeText[0] : brokeText[1]}/>
                    <div className='pt-10 flex flex-col items-center'>
                        <h1 className='text-2xl text-green-800 font-extrabold'> 2. Link Fidelity to Finturo </h1>
                        {/* <p className='text-sm text-center text-gray-600'> We will never share this information. </p> */}
                        <div className='pt-4'>
                            <FidSignUp></FidSignUp>
                        </div>
                    </div>
                </div>
                </>
        </div>
      );
}