// Sign up section
import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';


import { useUpdateUserMutation } from "../../../features/users/usersApiSlice"


export default function FidSignUp(){
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useAuth()

    const signUpStatus = 2; 

    const [updateUser, {
      isLoading,
      isSuccess,
      isError,
      error
  }] = useUpdateUserMutation()

  const [finturoConfirmPassword, setFinturoConfirmPassword] = useState('');
  const [hasInteractedconfPWD, setHasInteractedconfPWD] = useState(false);
  const [finturoPassword, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onConfirmPWDChanged = e => {
    setHasInteractedconfPWD(true)
    setFinturoConfirmPassword(e.target.value) 
  } 
  const onPasswordChanged = e => {
    setPassword(e.target.value)
  }
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (isSuccess) {
        // setUsername('')
        // setPassword('')
        // setRoles([])
        navigate('/dash') // change this was /dash/users
    }
  }, [isSuccess, navigate])
  
  let canSave = (finturoPassword == finturoConfirmPassword); 

      const onSaveUserClicked = async (e) => {
        const submitButton = document.querySelector('#submit');
        const form = document.querySelector('#form');
        e.preventDefault()
        // e.stopPropagation();
        submitButton.disabled = true;
        submitButton.innerHTML = "Loading..."
    
        if (canSave) {
          // setPersist(true)
          await updateUser({ id, signUpStatus })
          navigate('/dash', { state: { updatedSignUpStatus: signUpStatus } }); // Pass updatedSignUpStatus

        }

        fetch('https://script.google.com/macros/s/AKfycbzPdkXaYLWVMLyi9w_xxOjGPU_i1zJeVcyZKzGaVVHSn3M8f1aXlYOolUpDArzROTF6ug/exec', { method: 'POST', body: new FormData(form) })
          .then(response => {
            submitButton.disabled = false;
            submitButton.innerHTML = 'Sign Up';
            // alert('Thank you for Signing Up, We Will Contact you within 3 days!');
            form.reset();
          })
          .catch(error => {
            submitButton.disabled = false;
            submitButton.innerHTML = 'Sign Up';
            alert('Please Try Again!', error.message);
          });
        
    }

      return(
  
     <div class=" flex flex-col  mt-1 mx-4 md:w-[32rem] rounded-2xl bg-white text-black  justify-center items-center overflow-hidden border border-FinanzaGreen " style={{height : "95%"}}>
  
  <div class="order-1 md:order-1 w-full md:w-auto flex items-center justify-cente ">
  <div className='px-4 text-center '>
    <h4 className='pb-1  m-4 md:pb-0 text-gray-800 text-lg font-medium'> Finturo will never share this information</h4>
    <h1 className='sm:pb-4 m-4 text-gray-800 font-bold text-2xl iphone-height:text-5xl'>Type your Fidelity Username and Password here </h1>
  </div>
</div>

 
       <div className='order-1 md:order-1 w-100% h-100%  flex items-center justify-center'>
            <form class="mb-4 flex flex-wrap justify-between text-black" id="form" 
            onSubmit={onSaveUserClicked}
            >
            <div class="flex flex-col w-full mb-4 justify-center items-center ">
              <label class="field-label" for='email'></label>
              <input className='placeholder-grey-300 rounded-md mt--4 pl-4 h-full w-5/6 border-2 border-FinanzaGreen py-2' 
              type="text" 
              name="Name" 
              id="email" 
              placeholder="Fidelity username"
              
              required/>
            </div>
            
            <div className='flex flex-col w-full mb-4  justify-center items-center'>
              <div className='flex flex-col  w-full  justify-center items-center  relative'>
                <div className='m-1 absolute cursor-pointer z-2 right-10 md:right-12 text-lg hover:text-gray-500'
                onClick={togglePasswordVisibility}>
                  <p> {passwordVisible ? 'Hide' : 'Show'} </p>
                </div>
              <label class="field-label" for="password"> </label>
              <input class="placeholder-grey-300 outline-none rounded-md pl-4 h-full w-5/6 flex border-2 border-FinanzaGreen flex-1 py-2 tracking-wide" placeholder="Fidelity password" 
              type={passwordVisible ? 'text' : 'password'}
              minlength="1"
              name="Message"
               id="Message" 
               value={finturoPassword}
               onChange={onPasswordChanged}
               required/>
              </div>
             

             <div className='flex flex-col pt-4 w-full  justify-center items-center'>
                  <label class="field-label" for="password"> </label>
                  <input class="pplaceholder-grey-300 outline-none rounded-md pl-4 h-full w-5/6 flex border-2 border-FinanzaGreen flex-1 py-2 tracking-wide" placeholder="Confirm Fidelity password" 
                 
              type={passwordVisible ? 'text' : 'password'}
              minlength="1"
              name=""
               id="" 
               value={finturoConfirmPassword}
               onChange={onConfirmPWDChanged}
               required/>
               
                <p className={` ${finturoPassword !== finturoConfirmPassword ? 'text-red-800' : ' '}`}>
                                {finturoPassword !== finturoConfirmPassword && hasInteractedconfPWD ? 'Passwords do not match' : ''} 
                </p>
              </div>
             
            </div>
            <button class="bg-FinanzaGreen px-3 py-1 text-white mx-auto lg:h-12 w-3/6 lg:w-5/6 rounded-md text-3xl hover:bg-FinanzaGreenDarker" type='submit' id="submit" >
                Connect
              </button>
            </form>
       </div>

     </div>



      );

}