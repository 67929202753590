import image1 from './people/IMG_1491.JPG'
import image3 from './people/IMG_1514.JPG'
import sammy1 from './img/sammy.jpeg'


const captions = [
    {
        link: image1,
        name: "Julia Sandoval",
        statement: "I always felt overwhelmed and uncertain about investing but now I finally feel confident about my financial future.",
        bio:"31, Secretary"
    },
    {
        link: sammy1,
        name: "Samuel Barrera",
        statement: "I never had the time or knowledge to invest my hard-earned money. Finturo made it possible for me.",
        bio:"23, Barber"
    },
    {
        link: image3,
        name: "Erika Gonzalez",
        statement: "Finturo gave me the confidence to invest for my daughter's future.",
        bio:"31, Waiter"
    }
];

const captionsES = [
    {
        "link": image1,
        "name": "Julia Sandoval",
        "statement": "Siempre me sentí incierto, pero ahora finalmente me siento seguro acerca de mi futuro financiero.",
        "bio":"31, Secretaria"
    },
    {
            link: sammy1,
            name: "Samuel Barrera",
            statement: "Nunca tuve el tiempo o conocimiento para invertir mi dinero duramente ganado. Finturo lo hizo posible para mí.",
            bio:"23, Barbero"
        },
        {
            link: image3,
            name: "Erika Gonzalez",
            statement: "Finturo me dio la confianza para invertir en el futuro de mi hija.", 
            bio:"31, Mesera" 
        }
        
];

export default captions;
export {captionsES};