import React from 'react'
import Navbar from '../Navbar'

const Retirementcalc = () => {
  return (
    <>
    <Navbar/>
    <div className='h-full w-full text-center'>
      <p> What's your annual income?</p>
      <p>What age do you plan on retiring?</p>
      <p>Are you okay with keeping : ___</p>
    </div>
    </>
    
  )
}

export default Retirementcalc
