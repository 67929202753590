import React from 'react'

function errorPage() {
  return (
    <div>
        <p> Sorry this page was not found!</p>
    </div>
  )
}

export default errorPage