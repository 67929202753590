// Testimonial
import React from 'react'
import { useTranslation } from "react-i18next";
import Testimonial from './Testimonial';
import captions, {captionsES} from './captions';

export default function Testimonials(){
    const { t } = useTranslation();
    // const captionItems = t('caption', { returnObjects: true }); 
    // const caption = t('captions', { returnObjects: true });

        return(
            <div class="-mt-2 -mb-2 rounded-2xl bg-FinanzaGreen text-white shadow-2xl overflow-hidden font-Walsh2 md:text-sm" style={{ height: "95%", width: "98%" }}>
                <Testimonial items={localStorage.getItem('i18nextLng')==="en" ? captions : captionsES} autoSlideInterval ={5000} />; 
            </div>
        );
}