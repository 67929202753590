import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons"

export default function Milestone({ items, signUpStatus }) {
    return (
        <div>
            {items.map((item, index) => {
                let milestoneContent;

                // CURRENT STEP
                if (item.status === signUpStatus) {
                    milestoneContent = (
                        <div className="border border-lime-100 rounded-lg px-4 py-3 m-3 w-80 md:w-96 justify-between shadow shadow-green " 
                            key={index}
                            style={{
                                boxShadow: '0 0 6px 2px #75B570, 0 2px 4px -1px rgba(0, 128, 0, 0.06)',
                            }}
                        >
                            <div className="flex justify-between items-center pb-">
                                
                                <div className='flex'>
                                     <div className='border w-8 h-8 mt-0.5 mr-2 rounded'>
                                        <p className='text-center text-FinanzaGreen font-semibold text-2xl font-Walsh2 pt-0.5 opacity-500'> {item.status} </p>  
                                     </div>
                                     <div> 
                                        <p className="font-bold text-sm">{item.name}</p>
                                        <p className="text-gray-500 text-xs">{item.time}</p>
                                     </div>
                                    
                                </div>
                                <Link to={item.link}>
                                <div className="  relative ">                  
                                        <button 
                                        className="absolute top-1/2 left-1/2 transform -translate-x-full -translate-y-3/4 bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none "
                                        >
                                            Start
                                        </button>
                                </div>
                                </Link> 
                            </div>
                        </div>
                    );

                // COMPLETED STEP
                } else if (item.status < signUpStatus) {
                    milestoneContent = (
                        <div className="flex-row border border-gray-300 rounded-lg px-4 py-3 m-3 w-80 md:w-96 justify-between" key={index}>
                            <div className="flex justify-between items-center">
                                
                                <div className='flex'>
                                    <div className='border w-8 h-8 mt-0.5 mr-2 rounded'>
                                        <p className='text-center text-FinanzaGreen font-semibold text-2xl font-Walsh2 pt-0.5 opacity-500'> {item.status} </p>  
                                     </div>
                                    <div>
                                            <p className="font-bold text-sm">{item.name}</p>
                                            <p className="text-gray-500 text-xs italic">completed!</p>
                                    </div>
                                    
                                </div>
                                <div className="relative">
                                    <button 
                                    className="absolute top-1/2 left-1/2 transform -translate-x-full -translate-y-3/4 bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none"
                                    disabled={signUpStatus}
                                    >
                                        <div className='flex'>
                                         <p className='pr-1'> Done </p> 
                                         <span> <FontAwesomeIcon icon={faCircleCheck} /> </span>
                                        </div>
                                       
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                    
                // FUTURE STEP
                  } else { 
                    milestoneContent = (
                        <div className="flex-row border border-gray-300 rounded-lg  px-4 py-3 m-3 w-80 md:w-96 justify-between" key={index}>
                            <div className="flex justify-between items-center">
                                <div className="flex">
                                 <div className='border w-8 h-8 mt-0.5 mr-2 rounded'>
                                        <p className='text-center text-FinanzaGreen font-semibold text-2xl font-Walsh2 pt-0.5 opacity-500'> {item.status} </p>  
                                     </div>
                                    <div>
                                        <p className="font-bold text-sm">{item.name}</p>
                                        <p className="text-gray-500 text-xs">{item.time}</p>
                                    </div>
                                </div>
                                <div className="relative">
                                    <Link to={item.link}>
                                        <button
                                        className="absolute top-1/2 left-1/2 transform -translate-x-full -translate-y-3/4 bg-gray-400 text-white py-2 px-4 rounded-full focus:outline-none"
                                        disabled={signUpStatus}
                                        >
                                        Start   
                                        </button>
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    );
                }
                return milestoneContent;
            })}
        </div>
    );
}
