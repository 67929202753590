import { useState } from "react";
import { useGetUsersQuery } from "./usersApiSlice";
import User from './User'

const UsersList = () => {
  const [searchText, setSearchText] = useState("") 

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true, 
    refetchOnMountOrArgChange: true
  });

  let content;

  if (isLoading) content = <p>Loading...</p>;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { entities } = users;

    const filteredUsers = searchText
      ? Object.values(entities).filter(
          (user) =>
            user.finturoUsername.toLowerCase().includes(searchText.toLowerCase())
        )
      : Object.values(entities);

    const tableContent =
      filteredUsers.length > 0 &&
      filteredUsers.map((user, index) => (
        <User key={user.id} userId={user.id} isEvenRow={index % 2 === 0} />
      ));

    content = (
      <div className="mt-8">
        <div className="mb-4">
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search users..."
            className="p-2 border border-gray-400 rounded-lg w-full"
          />
        </div>
        <table className="w-full border-collapse">
          <thead className="bg-FinanzaGreen">
            <tr>
              <th scope="col" className="text-white py-3 px-6 text-left font-medium">Username</th>
              <th scope="col" className="text-white py-3 px-6 text-left font-medium">Roles</th>
              <th scope="col" className="text-white py-3 px-6 text-left font-medium">Info</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </div>
    );
  }

  return content;
};

export default UsersList;
