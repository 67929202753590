import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    // let isManager = false
    let isAdmin = false
    let status = "User"
    let onboarded = true

    if (token) {
        const decoded = jwtDecode(token)
        const { finturoUsername, firstName, signUpStatus, monthlyContribution, FXAIX, SPAXX, startDate,
        startingAmount, roles, id } = decoded.UserInfo
        const lowercaseRoles = roles.map(role => role.toLowerCase());

        // isManager = roles.includes('Manager')
        isAdmin = lowercaseRoles.includes('admin')

        if (signUpStatus < 4 ) onboarded = false
        if (isAdmin) status = "admin"


        return {  finturoUsername, firstName, signUpStatus, monthlyContribution, FXAIX, SPAXX, startDate, startingAmount,
             roles, status, isAdmin, id, onboarded}
    }

    return { username: '', roles: [], isAdmin, status }
}
export default useAuth