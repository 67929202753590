import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, {useEffect, useState, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { disableReactDevTools} from '@fvilers/disable-react-devtools'

import './index.css';
import { store } from './app/store'
import { Provider } from 'react-redux';
import App from './App';
import LoadingMarkup from './components/LoadingMarkup';
import './flag-icon-css/css/flag-icons.min.css'


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    
    // lng: document.querySelector('html').lang, // if you're using a language detector, do not define the lng option
    supportedLngs: ['en','es'],
    fallbackLng: "en",
    detection: {
      order: ['path','cookie', 'htmlTag' ,'localStorage', 'subdomain'],
      cashes: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json', // this is where you load in translation files
    }
  
  });

// function App() {
//   const { t } = useTranslation();
// ​
//   return <h2>{t('Welcome to React')}</h2>;
// }

const loadingScreen = <div className='flex flex-col h-screen w-screen items-center justify-center'> 
<h2 className="text-2xl classic-4 text-FinanzaGreen">Loading...</h2>
</div>

if (process.env.NODE_ENV === 'production') disableReactDevTools() 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<LoadingMarkup/>}>
    <React.StrictMode>
      <Provider store = { store }> 
    <BrowserRouter>
      <Routes>
        <Route path="/*"  element={<App />} />
      </Routes>
    </BrowserRouter>
    </Provider>
    </React.StrictMode>
  </Suspense> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
