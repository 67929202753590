import { React, useState, useEffect, useRef } from 'react';
import {Link } from 'react-router-dom';

import DashHeader from '../../DashHeader';
import RothImg from '../img/RothImg.png';
import BrokerageImg from '../img/BrokerageImg.png';

const Goal = () => {
    const [selectedColumn, setSelectedColumn] = useState(null);

    const [leftDivHeight, setLeftDivHeight] = useState('250px'); // Default height
    const [rightDivHeight, setRightDivHeight] = useState('250px'); // Default height

    const [showRecommendationLeft, setShowRecommendationLeft] = useState(false);
    const [showRecommendationRight, setShowRecommendationRight] = useState(false);


    const headerTextColor = {
        color: '#75B570',
    };
    
    const handleColumnClick = (column) => {
        setSelectedColumn((prevColumn) => (prevColumn === column ? null : column));

        if (column === 'left') {
            setLeftDivHeight((prevHeight) => (prevHeight === '250px' ? '540px' : '250px'));
            setRightDivHeight('250px');

            //setShowRecommendationLeft(true);
            setShowRecommendationLeft(false);
            

            setTimeout(() => {
                setShowRecommendationLeft(true);
            }, 135);

        } else if (column === 'right') {
            setRightDivHeight((prevHeight) => (prevHeight === '250px' ? '450px' : '250px'));
            setLeftDivHeight('250px');

            setShowRecommendationRight(false);

            // Set a timeout to show the recommendation div after 0.3 seconds
            setTimeout(() => {
                setShowRecommendationRight(true);
            }, 135);
        }
    };

    const isNextButtonDisabled = selectedColumn === null;

    const SelectionIndicator = () => (
        <div
            className="absolute top-0 right-0 transform translate-x-1 -translate-y-1"
            style={{
                width: '0',
                height: '0',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderLeft: '10px solid #75B570',
            }}
        >
            <div
                className="absolute transform translate-x-[-7px] translate-y-[3px] rotate-45"
                style={{
                    content: '',
                    width: '10px',
                    height: '10px',
                    borderTop: '2px solid #fff',
                    borderRight: '2px solid #fff',
                }}
            />
        </div>
    );

    const content = (
        <>
      
        <div 
            className='text-black md:pt-20 side-phone:py-4 w-screen'
            style={{
                paddingTop: selectedColumn ? '0' : '50px',
                transition: 'padding-top 0.3s ease-in-out',
            }}
        >
            
                <div className='flex flex-col items-center md:px-20 pt-6'>
                <DashHeader></DashHeader> 
                    <h1 className=' md:mt-48 text-3xl text-center mx-1 '> <span className='text-FinanzaGreen'> Click</span> the account you would like to  <span className='text-FinanzaGreen'> continue</span></h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-0 m-6 pb-60'>
                
                {/* left div */}
                <div
                id='left'
                className={`m-2 rounded-md  ${selectedColumn === 'left' ? 'border-2 border-FinanzaGreen' : ''} shadow-[0_0_6px_2px_#b5b3b3] hover:shadow-[0_0_6px_2px_#75B570]`}
                style={{
                    height: leftDivHeight,
                    transition: 'height 0.3s ease-in-out',
                }}
                onClick={() => handleColumnClick('left')}
                > 
                    {selectedColumn === 'left' && <SelectionIndicator />}
                    <h1 className="text-lg p-2 text-center font-bold"> Retirement Account with <span className='text-FinanzaGreen'> Tax Advantages </span></h1>
                    <p className="text-center text-gray-500"> Tax Free Profits with Withdrawal Restrictions </p>
                    
                    {/* recommendation div */}
                    {selectedColumn === 'left' && showRecommendationLeft &&  (
                        <div>
                             <div className="mx-10 mt-10 border border-FinanzaGreen rounded">
                        {/* <h1 className="text-lg pt-8 text-center font-bold"> With this goal, </h1>  */}
                        <h1 className="text-lg text-center font-bold"> We recommend our <span style={headerTextColor}> Roth IRA plan</span></h1> 
                        <ul 
                        className="text-center p-3 font-bold"
                        >
                             {/* <p className='m-4 text-center text-gray-600 text-lg'> A Roth IRA is a <span className="text-green-800 font-bold">tax-advantaged</span> retirement account. You put money into it, and that money grows over time as we invest it in things like stocks and bonds <span className="text-green-800 font-bold">on your behalf</span>. With a Roth IRA, when you take the money out later, you don't have to pay extra taxes on the money you've earned from those investments.</p> */}
                             <li className="text-center p-1 md:p-2 lg:p-4 text-red-500">- Withdrawals before 59.5 result in a penalty with a few exceptions*</li>
                            <li className="text-center p-1 md:p-2 lg:p-4 text-FinanzaGreenDarker" >+ No Taxes on Profits</li>
                            <li className="text-center p-1 md:p-2 lg:p-4 text-FinanzaGreenDarker">+ Generate long term wealth </li>
                         </ul>
                        </div>
                        <ul>
                        <p className='px-10 font-semibold'><span className='text-red-500'> * </span> Can withdraw 10k after 5 years for your <span className='text-FinanzaGreenDarker'>first down payment</span></p>
                        <p className='px-10 font-semibold'><span className='text-red-500'> * </span> Can withdraw a certain amount for <span className='text-FinanzaGreenDarker'> college expenses </span></p>
                        </ul>
                        </div>
                       
                    )} 
                        <div 
                        className={`m-6 flex items-center justify-center ${selectedColumn === 'left' ? 'opacity-0' : 'opacity-100'}`}
                        style={{
                            //transition: 'opacity 0.3s ease-in-out',
                        }}
                        >
                            <img className='h-24 w-24 md:h-26 md:w-26 lg:h-32 lg:w-32 text-center' src={BrokerageImg}></img>
                        </div>
                </div>

                {/* right div */}
                <div 
                id='right'
                className={`m-2 rounded-md shadow-[0_0_6px_2px_#b5b3b3] hover:shadow-[0_0_6px_2px_#75B570] ${
                    selectedColumn === 'right' ? 'border-2 border-FinanzaGreen' : ''
                }`}
                style={{
                    height: rightDivHeight,
                    transition: 'height 0.3s ease-in-out',
                }}
                onClick={() => handleColumnClick('right')}
                >
                    {selectedColumn === 'right' && <SelectionIndicator />}
                    <h1 className="text-lg md:text-xl p-2 text-center font-bold lg:px-4"> Investment Account with No Tax Advantages </h1>
                    <p className="text-center text-gray-500"> Some taxes on profits with <span className='text-FinanzaGreen'>no restrictions </span></p>
                    
                    {/* recommendation div */}
                    {selectedColumn === 'right' && showRecommendationRight && (
                        <div  
                        className="m-10 border border-FinanzaGreen rounded"
                        >
                        {/* <h1 className="text-lg pt-8 text-center font-bold"> With this goal, </h1>  */}
                        <h1 className="text-lg text-center font-bold"> We recommend our <span style={headerTextColor}> Brokerage plan</span></h1> 
                        <h3 className='text-center text-base font-extrabold pt-2'> Important Details: </h3>
                        <ul className="text-center  font-bold">
                        {/* <p className='m-4 text-center text-gray-600 text-lg'> A Brokerage account is a <span className="relative inline-block text-green-800 font-bold">versatile investment </span> account. You put money into it, and that money grows over time as we invest it in things like stocks and bonds <span className="text-green-800 font-bold">on your behalf</span>. </p> */}
                        <li className="text-center p-1 md:p-2 lg:p-4 text-red-500" >- Any Profits Will be Taxed</li>
                            <li className="text-center p-1 md:p-2 lg:p-4 text-FinanzaGreenDarker">+ Withdraw money at any time</li>
                            <li className="text-center p-1 md:p-2 lg:p-4 text-FinanzaGreenDarker">+ Generate long term wealth </li> 
                           
                         </ul>
                        </div>
                         
                    )}
                    
                    <div 
                    className={`m-6 flex items-center justify-center ${selectedColumn === 'right' ? 'opacity-0' : 'opacity-100'}`}
                    style={{
                        //transition: 'opacity 0.3s ease-in-out',
                    }}
                    >
                        <img className='h-24 w-24 md:h-26 md:w-26 lg:h-32 lg:w-32 text-center' src={RothImg}></img>
                    </div>
                    
                
                </div>

                <div className="fixed md:relative bottom-0 left-0 w-full bg-white py-4 border-t md:border-0 border-gray-300 bg-grey-100">
                        <div className="flex justify-center">
                            {/* <Link to="/dash">
                                <button className="mx-2 px-4 py-2 text-white bg-FinanzaGreen hover:bg-FinanzaGreenDarker py-2 px-4 rounded-full border border-black">
                                    Back
                                </button>
                            </Link> */}

                            <Link to={selectedColumn === ('left' & !isNextButtonDisabled) ? '/dash/fidelityRothIRA' : '/dash/fidelityBrokerage'}>
                                <button 
                                    className={`mx-2 px-20 py-2 text-white bg-FinanzaGreen hover:bg-FinanzaGreenDarker py-2 px-4 rounded-md border border-black ${isNextButtonDisabled ? 'opacity-70' : ''}`}
                                    disabled={isNextButtonDisabled}                            
                                >
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>

                </div>

                   

                </div>
            </div>
        </>
    );
    return content;
};

export default Goal;
