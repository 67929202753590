import {React} from 'react'

export default function AccountType({items}){

    const text = items; 
    
    // <h1 className='p-4 mt-4 text-2xl font-bold text-center'> {text.accountName} </h1>
    // <p className='m-4 text-center text-gray-600 text-lg'> A Brokerage account is a <span className="relative inline-block text-green-800 font-bold">versatile investment </span> account. You put money into it, and that money grows over time as we invest it in things like stocks and bonds <span className="text-green-800 font-bold">on your behalf</span>. </p>
    // <p className='m-4 text-center text-gray-600 text-lg'> {text.secondText}</p>

//     <div className="m-8 border border-FinanzaGreen rounded-lg">
//     <h1 className='p-4 mt-4 text-2xl font-bold text-center'> What is a Roth IRA?</h1>
//     <p className='m-4 text-center text-gray-600 text-lg'> A Roth IRA is a <span className="text-green-800 font-bold">tax-advantaged</span> retirement account. You put money into it, and that money grows over time as we invest it in things like stocks and bonds <span className="text-green-800 font-bold">on your behalf</span>. With a Roth IRA, when you take the money out later, you don't have to pay extra taxes on the money you've earned from those investments.</p>
//     <p className='m-4 text-center text-gray-600 text-lg'> With some exceptions, withdrawing must be taken after the age of 59 and after a 5 year holding period. The contribution limit for a Roth IRA is $6,500 per year or $7,500 if you're over the age of 50. You must make less than $153,000 for single tax filers, and less than $228,000 for those married, and filing jointly.</p>
// </div>
  return (
    <> 
       
          <div className=' md:px-10 pt-10 flex flex-col items-center w-full'>
              <h1 className='text-2xl text-gray-900 font-extrabold text-center'> {text.stepOne} </h1>
              <div className="m-8 border border-FinanzaGreen rounded-lg flex flex-col items-center">
                  <p className='m-4 text-gray-600 text-base'> {text.syncs} <span className="text-green-800"> {text.span}</span></p>
                  <button className='bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none flex justify-center'>
                                    <h3 className='text-center text-white text-3xl flex'> 
                                        <a className="text-xl" href={text.link} target="_blank"> Create Account</a>
                                    </h3>
                    </button>
                  <p className='m-4 text-sm text-center text-gray-600'> Already have a Fidelity account? Continue below! </p>
              </div>
          </div>
    </>
  );
};


