import { useTranslation } from "react-i18next";
import HomePage from './components/Landingpage/HomePage';
import FAQ from './components/FAQpage/FAQ';

import {Link, Route, Routes } from 'react-router-dom';
// import './App.css';
import './index.css'
import Errorpage from './components/404'
import Layout from './components/layout'
import Login from './components/Onboarding/Step0/Login'
import SignUp from './components/Onboarding/Step0/SignUp'
import DashLayout from "./components/DashLayout";
import Welcome from "./components/Onboarding/Step0/Welcome";
import UsersList from "./features/users/UsersList";
import  EditUser from "./features/users/editUser";
import NewUserForm from "./features/users/NewUserForm";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import { ROLES } from "./config/roles";
import RequireAuth from "./features/auth/RequireAuth";
import RequireOnboard from "./features/auth/RequireOnboard";
import FidelityRothIRA  from "./components/Onboarding/Step1/accountTypes/FidelityRothIRA";
import FidelityBrokerage  from "./components/Onboarding/Step1/accountTypes/fidelityBrokerage";
import Goal from "./components/Onboarding/Step1/goal"
import BankAccount from "./components/Onboarding/Step2/ConnectBank/BankAccount.js";
import BankTrans from "./components/Onboarding/Step2/BankTrans";
import FundingYourFuture from "./components/Onboarding/Step3/FidelityPlan";
import Homescreen from "./components/MVP/homescreen";
import Retirementcalc from "./components/Landingpage/Retirementcalc.js";


function App() {

const { t } = useTranslation()
  
   return (
    <>
      <div>      
      <Routes> 
         
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<HomePage />}/>
          <Route path="/retcalc" element={<Retirementcalc/>}/>
            <Route path="/FAQ" element={<FAQ />}/>
         <Route path="login" element={<Login />}/>
         <Route path="signup" element={<SignUp />}/>
         <Route path="dashi" element={<DashLayout />}>

                <Route path="goal">
                    <Route index element={<Goal />} />
                </Route>
                
                <Route path="fidelityBrokerage">
                    <Route index element={<FidelityBrokerage />} />
                </Route>

                <Route path="fidelityRothIRA">
                    <Route index element={<FidelityRothIRA />} />
                </Route>

                <Route path="chooseAmount">
                    <Route index element={<BankTrans />} />
                </Route>
                <Route path="connectBank">
                    <Route index element={<BankAccount />} />
                </Route>
                <Route path="fundingYourFuture">
                   <Route index element={<FundingYourFuture/>}/> 
                </Route>
         </Route>
          
         {/* protected Routes */}
            <Route element={<PersistLogin />}> 
            {/* here add the element you want to be allowed access  */}
            <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]}/>}> 
            <Route element={<Prefetch />}> 
                <Route path="dash" element={<DashLayout />}>
                
                <Route index element={<Welcome />} />
                
                <Route path="goal">
                    <Route index element={<Goal />} />
                </Route>

                <Route path="fidelityBrokerage">
                    <Route index element={<FidelityBrokerage />} />
                </Route>

                <Route path="fidelityRothIRA">
                    <Route index element={<FidelityRothIRA />} />
                </Route>

                <Route path="chooseAmount">
                    <Route index element={<BankTrans />} />
                </Route>
                <Route path="connectBank">
                    <Route index element={<BankAccount />} />
                </Route>

                <Route path="fundingYourFuture">
                   <Route index element={<FundingYourFuture/>}/> 
                </Route>
               
                
                
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}> 
                <Route path = "users"> 
                    <Route index element = {<UsersList/>}/>
                    <Route path=":id" element={<EditUser/>} />
                    <Route path="new" element={<NewUserForm/>} />
                </Route>
                </Route>
                <Route element={<RequireOnboard/>}> 
                <Route path="home">
                    <Route index element={<Homescreen/>}/>
                </Route>
                </Route>
                </Route>
                </Route>
                
           </Route>
        </Route>
        {/* end protected route */}
        </Route>   {/* { Private, Public structure } */}
        <Route path="*" element={<Errorpage/>}/>
      </Routes>
      </div>
    </>
  );
}

export default App;

// return (
  //   <div>
  //       <HomeList></HomeList>
  //   </div>
  // );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Finanza <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
