import React from 'react'

export default function instructions({items}) {
   
    return (
        <div>
            
            {items.map((item, index) => (
                <div 
                className='flex gap-x-2 py-2'
                key={index}>
                     <div>
                      <div className='bg-FinanzaGreen rounded-2xl p-[0.05rem]'>
                        <p> {item.number} </p>
                     </div>   
                     </div>
                   <div className='font-semibold'>
                   <p> {item.text}  </p>  
                    </div>
                </div>
            ))}
        </div>
    );
}

   

