
const questions = [
    {
        question: "What is Finturo?",
        answer: "Finturo is a financial planning app designed to help Hispanics in the United States create wealth over time specifically for their retirement."
      },
      {
        question: "Is Finturo only for Hispanics?",
        answer: "No, while Finturo's mission is to provide financial advice specifically for Hispanics, its services are available to anyone in the United States who wants to use them."
      },
      {
        question: "What is a Roth IRA?",
        answer: "IRA stands for individual retirement account, which is a retirement savings accounts with tax advantages. \
        The tax advantage for a Roth IRA is you don't have to pay taxes on any profits if you wait untill you're 59 1/2. Sign \
        up for Finturo today to see if you qualify for a Roth IRA!"
      },
      {
        question: "What happens if I withdraw early from my Roth IRA?",
        answer: "If you withdraw money before 59 1/2 from your Roth IRA (and your withdrawal doesn't qualify) you pay two fees: a capital gains tax on any profits \
        and also a 10% early withdrawal fee. The 10% fee is on the amount you withdrew. The capital gains tax you pay is dependent on your income bracket. \
        We recommend avoiding withdrawing early to avoid paying the fees!"
      },
      {
        question: "What is a 401k?",
        answer: "A 401k is an employer sponsored retirement plan. As with an IRA, you must be at least 59 1/2 to withdraw money without paying early withdrawal fees. \
        Usually, your employer will match whatever you contribute to the \
        plan up until a certain amount. The exact amount that gets matched depends on your employer."
      },
      {
        question: "What is a Roth 401k?",
        answer: "A Roth 401k is a 401k plan that allows you to not pay capital gains taxes upon withdrawal from your plan.\
        The catch is that you must contribute money to the plan that you have already paid taxes on (post-tax dollars). \
        "
      },
      {
        question: "How does Finturo work?",
        answer: "Finturo works by offering a robo-adviser service that helps users track their money and invest it for long-term growth. Users pay a monthly fee to access the service and can monitor their investments through the Finturo app."
      },
      {
        question: "What are the benefits of using Finturo?",
        answer: "By using Finturo, users can receive personalized financial advice, invest their money for long-term growth using a robo-adviser service, access an easy-to-use mobile app, benefit from affordable pricing, and join a community-driven platform that emphasizes financial literacy and education."
      },
      {
        question: "Is my money safe with Finturo?",
        answer: "Yes, Finturo takes the security of users' personal and financial information seriously and uses industry-standard encryption technology to protect it. (Disclaimer*)"
      },
      {
        question: "What is a robo-adviser and how does it work?",
        answer: "A robo-advisor is an automated investment platform that uses algorithms to manage investment portfolios based on user preferences and market trends."
      },
      // {
      //   question: "How much does Finturo cost?",
      //   answer: "Finturo charges $5 per month per user and $15 per month per family for access to their investment management services through a robo-advisor."
      // },
      {
        question: "Can I cancel my Finturo account at any time?",
        answer: "You will miss out on our automatic investment, but yes you can cancel at any time."
      },
      {
        question: "What if my employer already has a retirement plan (401k)?",
        answer: "We connect your 401k to make it easier to understand and manage."
      }
    ];    
    
const preguntas = [
    {
        question: "¿Qué es Finturo?",
        answer: "Finturo es una aplicación de planificación financiera diseñada para ayudar a los hispanos en Estados Unidos a crear riqueza a lo largo del tiempo, específicamente para su jubilación."
      },
      {
        question: "¿Finturo es solo para hispanos?",
        answer: "No, aunque la misión de Finturo es proporcionar asesoramiento financiero específicamente para hispanos, sus servicios están disponibles para cualquier persona en Estados Unidos que desee utilizarlos."
      },
      {
        question: "¿Qué es una cuenta IRA Roth?",
        answer: "Una IRA es una cuenta de jubilación individual,una cuenta de ahorro para la jubilación con ventajas fiscales. \
        La ventaja fiscal de una cuenta IRA Roth es que no tiene que pagar impuestos sobre las ganancias si espera hasta los 59 años y medio. ¡Regístrese en Finturo hoy para ver si califica para una Roth IRA!"
      },
      {
        question: "¿Qué sucede si hago un retiro anticipado de mi Roth IRA?",
        answer: "Si retira dinero antes de los 59 1/2 de su Roth IRA (y su retiro no califica), paga dos tarifas: un impuesto sobre las ganancias de capital sobre cualquier ganancia \
        así como un cargo por retiro anticipado del 10%. La tarifa del 10% es sobre la cantidad que retiró. El impuesto sobre las ganancias de capital que paga depende de su nivel de ingresos. \
        ¡Recomendamos evitar retirar antes de tiempo para evitar pagar las tarifas!"
      },
      {
        question: "¿Qué es una cuenta 401k?",
        answer: "Un 401k es un plan de jubilación patrocinado por el empleador. Al igual que con una IRA, debe tener al menos 59 1/2 años para retirar dinero sin pagar cargos por retiro anticipado. \
        Por lo general, su empleador igualará lo que usted contribuya al \
        planificar hasta cierta cantidad. La cantidad exacta que se iguala depende de su empleador."
      },
      {
        question: "¿Qué es una cuenta Roth 401k?",
        answer: "Una cuenta de Roth 401k es un plan 401k que le permite no pagar impuestos sobre las ganancias al retirarse de su plan.\
        Usted contribuye dinero al plan sobre el que ya pagó impuestos.\
        "
      },
      {
        question: "¿Cómo funciona Finturo?",
        answer: "Finturo funciona ofreciendo un servicio de asesoramiento robótico que ayuda a los usuarios a realizar un seguimiento de su dinero e invertirlo para un crecimiento a largo plazo. Los usuarios pagan una tarifa mensual para acceder al servicio y pueden monitorear sus inversiones a través de la aplicación Finturo."
      },
      {
        question: "¿Cuáles son los beneficios de usar Finturo?",
        answer: "Al usar Finturo, los usuarios pueden recibir asesoramiento financiero personalizado, invertir su dinero para un crecimiento a largo plazo utilizando un servicio de asesoramiento robótico, acceder a una aplicación móvil fácil de usar, beneficiarse de precios asequibles y unirse a una plataforma impulsada por la comunidad que enfatiza la educación financiera."
      },
      {
        question: "¿Mi dinero está seguro con Finturo?",
        answer: "Sí, Finturo toma en serio la seguridad de la información personal y financiera de los usuarios y utiliza tecnología de cifrado de estándar de la industria para protegerla. (Descargo de responsabilidad*)"
      },
      {
        question: "¿Qué es un asesor robótico y cómo funciona?",
        answer: "Un asesor robótico es una plataforma de inversión automatizada que utiliza algoritmos para gestionar carteras de inversión basadas en las preferencias del usuario y las tendencias del mercado."
      },
      // {
      //   question: "¿Cuánto cuesta Finturo?",
      //   answer: "Finturo cobra $5 por mes por usuario y $15 por mes por familia para acceder a sus servicios de gestión de inversiones a través de un asesor robótico."
      // },
      {
        question: "¿Puedo cancelar mi cuenta en Finturo en cualquier momento?",
        answer: "Perderás nuestras inversiones automáticas, pero sí, puedes cancelar en cualquier momento."
      },
      {
        question: "¿Qué sucede si mi empleador ya tiene un plan de jubilación (401k)?",
        answer: "Conectamos tu 401k para facilitar su comprensión y gestión."
      }
    ];

export default questions;
export {preguntas};
