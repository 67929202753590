import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authAPIslice"
import Navbar from "../../components/Navbar"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"
import LoadingMarkup from "../../components/LoadingMarkup"

const PersistLogin = () => {

    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)

    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()


    useEffect(() => {

        if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode

            const verifyRefreshToken = async () => {
                console.log('verifying refresh token')
                try {
                    //const response = 
                    await refresh()
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    console.error(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        }

        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])


    let content
    if (!persist) { // persist: no
        console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        console.log('loading')
        content = <LoadingMarkup/>
    } else if (isError) { //persist: yes, token: no
        console.log('error') // THis line is where the "Please login again." in "Unauthorized - Please login again" comes from. 
        content = (
            <div className="w-full h-screen ">
                <Navbar></Navbar>
             <div className=" flex flex-col h-full justify-center items-center gap-y-2">
               
                <h1 className="text-2xl text-center">For your safety, you have been logged out </h1>
                
                <Link to="/login">
               <button className="">
               <p className='errmsg text-2xl  border-2 border-slate-900 rounded-xl px-2 bg-FinanzaGreen py-4'>
               Please click here to login again
               </p>
               </button>    
              

                </Link>
              
                <p className=""> System message: {`${error?.data?.message}`} </p>
            </div>
            </div>
        )
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        console.log('token and uninit')
        console.log(isUninitialized)
        content = <Outlet />
    }

    return content
}
export default PersistLogin