import React from 'react'
import { Outlet } from 'react-router-dom'

import DashHeader from './DashHeader'
import DashFooter from './DashFooter'

const DashLayout = () => {
  return (
    <div className='dash-container'>
        <div className="side-phone:block md:flex md:items-center md:justify-center side-phone:h-fit md:h-screen">
        <DashHeader/>
          <div>
            <Outlet/>
          </div>
        </div>
        {/* <DashFooter/> */}
    </div>
  )
}

export default DashLayout