import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { useDispatch } from 'react-redux'

import { setCredentials } from '../../../features/auth/authSlice'
import { useLoginMutation } from '../../../features/auth/authAPIslice'

import usePersist from '../../../hooks/usePersist'
import logo from '../../Finturalogo.svg'
import couplephoto from '../img/couplephoto.jpg'
import LoadingMarkup from '../../LoadingMarkup'

const Login = () => {
    const Ref = useRef(null)
    const userRef = useRef()
    const errRef = useRef()
    const [finturoUsername, setUsername] = useState('') 
    const [finturoPassword, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist() 

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()
    const [time, setTime] = useState(30);

    // useEffect(() => {
    //     userRef.current.focus()
    // }, [])

    useEffect(() => {
        setErrMsg('');
    }, [finturoUsername, finturoPassword])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ finturoUsername, finturoPassword }).unwrap() //only happens if sucessful
            // console.log( accessToken )
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            
            // console.log(`signupstatus: ${signUpStatus}`)
            const myBool = (((jwtDecode(accessToken)).UserInfo).signUpStatus >= 4)
            console.log('navigating from login page')
            navigate(`/dash${myBool ? '/home' : ''}`)
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized | Username or Password Incorrect ');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = e => {
        setUsername(e.target.value.toLowerCase());
    }
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg text-red-400 font-bold" : "offscreen"

    const content = (
        
        <section className="h-screen w-screen px-1 absolute side-phone:pb-0 pb-20 ">
            <div className='  flex flex-col md:flex-row md:justify-center items-center relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <div className=' flex flex-col items-center px-3 py-3 lg:mr-20 border-gray-950 rounded-2xl sm:pr-10 '>
            {/* drop-shadow-2xl border-2*/}
            <div className='font-bold text-2x1 cursor-pointer flex items-center font-[Poppins] text-gray-800 side-phone:mb-0  mr-2 '>
                    <div>
                    <Link to="/">
                          <img className='h-32 ' alt='Finturo logo' src={logo}></img>
                    </Link>
                       
                    </div>
                    {/* <p className="text-FinanzaGreen font-body2  -ml-1" style={{fontSize: '1.875rem'}}>
                      <Link to="/">Finturo</Link>
                      </p> */}
            </div>
            <header className=' text-center font-Walsh2'>
                <h1 className='text-3xl font-semibold'>Welcome back</h1>
                <h2 className='pt-3'>Login to see an update on your financial journey</h2>
            </header>
            <main className="login ">
                <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                <form className="flex flex-col side-phone:pt-8 pt-12 font-body2" onSubmit={handleSubmit}>
                    <label htmlFor="username" className=''>Username:</label>
                    <input
                        className="border-b-2 border-gray-300 focus:border-2 focus:border-FinanzaGreen focus:outline-none focus:rounded"
                        type="text"
                        id="username"
                        ref={userRef}
                        value={finturoUsername}
                        onChange={handleUserInput}
                        autoComplete="off"
                        required
                    />

                    <label htmlFor="password" className='pt-8'>Password:</label>
                    <input
                        className="border-b-2 border-gray-300  focus:border-2 focus:border-FinanzaGreen focus:outline-none focus:rounded"
                        type="password"
                        id="password"
                        onChange={handlePwdInput}
                        value={finturoPassword}
                        required
                    />

                    <button className="bg-FinanzaGreen rounded-lg side-phone:mt-8 mt-20 text-white h-7 w-full font-Walsh1">Sign In</button>


                    <label htmlFor="persist" className="form__persist">
                        <input
                            type="checkbox"
                            className="form__checkbox"
                            id="persist"
                            onChange={handleToggle}
                            checked={persist}
                        />
                        Trust This Device
                    </label>                    

                </form>
            </main>
            </div>
            <div className='hidden sm:block '>

                <div className='w-80 h-72 bg-FinanzaGreen rounded-xl mb-8 relative'>
                </div>
                <div className='absolute z-10  pl-4 bottom-8 sm:bottom-8 md:bottom-12 lg:bottom-24'>
                    <img className='object-cover w-80 rounded-xl border-2 border-slate-600' src={couplephoto}></img>
                </div>
            </div>

            <footer>
            </footer>
            </div>
            
        </section>
    )
    
    if (isLoading) return <LoadingMarkup/>
    return content
}
export default Login