import React, {useState} from 'react'
import useCountdown from '../hooks/useCountdown.js'

const LoadingMarkup = () => {
  const { secondsLeft, start } = useCountdown(40);
  // const [secondsLeft, setSecondsLeft] = useState(0);
  

  return (
    <div className='flex flex-col h-screen w-screen items-center justify-center'>
    {secondsLeft <= 27 ? (
      <>
        <div className='text-2xl text-FinanzaGreen text-center'>
        <p className='pb-2'>{`Sit tight! We're loading your content.`}</p>
        <p>
          {`Time remaining: ${secondsLeft} seconds`}
        </p>
        </div>
        
      </>
    ) : (
      <h2 className="text-2xl classic-4 text-FinanzaGreen">Loading...</h2>
    )}
  </div>
  )
}

export default LoadingMarkup
