import React, { useState, useEffect } from 'react';
import { useTranslation,  Trans} from "react-i18next";

import Question from './ques';
import questions, { preguntas } from './Q&A';
import Navbar from '../Navbar';


export default function FAQ() {
  
  const { t } = useTranslation()

  const language = localStorage.getItem('i18nextLng');
  
  return (
    <>
    <Navbar></Navbar>
    <div className="w-screen font-Anupan1 overflow-hidden">
      <div className="md:mt-14 pt-24 w-4/5 md:pt-10 ml-[18%] side-galaxy:ml-[35%] ">
        <div>
          <h1 className="text-4xl font-bold">{t('QAtitle')}</h1>
        </div>
        <div className="pt-3 text-gray-600 text-2xl">
        <Trans i18nKey="subheaderText">
        Grow your <span className='text-FinanzaGreen'>{t('subheaderdifcol')}</span> today
      </Trans>
        </div>
      </div>
      {/* Question List */}
      <div className="flex flex-col ml-[18%] side-galaxy:ml-[35%] mr-[20%] side-galaxy:mr-[30%] my-8 pt-10 ">
        <Question items={language === 'en' ? questions : preguntas} />
      </div>
    </div>
    </>
  );
}
