// Sign up section
import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";


export default function OldSignUp(){
    const { t } = useTranslation()

      return(
  
     <div class="relative flex flex-col lg:flex-row -mt-2 -mb-2 rounded-2xl bg-white text-black shadow-2xl relative flex justify-center items-center overflow-hidden" style={{height : "95%", width: "98%"}}>
  
  <div class="order-1 md:order-1 w-full md:w-auto flex items-center justify-center">
  <div className='px-4 text-center'>
    <h1 className='sm:pb-4 text-black font-Walsh2 font-bold text-3xl iphone-height:text-5xl'>{t('sign_up_header')}</h1>
    <h4 className='pb-1 iphone-height:pb-8 md:pb-0 text-gray-800 font-Walsh2 text-md iphone-height:text-3xl'>{t('sign_up_sub_text')}</h4>
    <Link to="/retcalc">
    <button class="bg-FinanzaGreen px-3 py-1 text-white mx-auto lg:h-12 w-5/6 lg:w-5/6 rounded-md text-3xl font-Walsh1" type='submit' id="submit" >
              {t('sign_up_btn')}
      </button>
    </Link>
   
  </div>
</div>

 
       {/* <div className='order-1 md:order-1 w-100% h-100%  flex items-center justify-center'>
            <form class="mb-4 flex flex-wrap justify-between text-black" id="form" 
            >
            <div class="font-Walsh2 flex flex-col w-full mb-4 lg:h-12 justify-center items-center ">
              <label class="field-label" for='email'></label>
              <input className='placeholder-grey-300 rounded-md mt--4 pl-4 h-full w-5/6 border-2 border-FinanzaGreen' type="text" name="Name" id="email" placeholder={t('type_name')}></input>
            </div>
            
            <div className='flex flex-col w-full mb-4 font-Walsh1 lg:h-12 justify-center items-center'>
              <label class="field-label" for="password"></label>
              <textarea class="placeholder-grey-300 outline-none rounded-md pl-4 h-full w-5/6 flex border-2 border-FinanzaGreen flex-1 py-2 font-Walsh2" placeholder={t('type_email')} type="text" name="Message" id="Message"></textarea>
            </div>
            <button class="bg-FinanzaGreen px-3 py-1 text-white mx-auto lg:h-12 w-3/6 lg:w-5/6 rounded-md text-3xl font-Walsh1" type='submit' id="submit" >
              {t('sign_up_btn')}
              </button>
            </form>
       </div> */}

     </div>



      );

}