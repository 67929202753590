import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from "@fortawesome/free-solid-svg-icons"


const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'us'
    },
    {
      code: 'es',
      name: 'Español',
      country_code: 'es'
    }
  ];
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  
  export default function Langbtn() {
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(["i18nextLng"]);
    const currentLanguageCode = cookies.i18nextLng;
  
    const changeLanguage = (code) => {
      i18next.changeLanguage(code);
      setCookie("i18nextLng", code);
    };

    console.log(currentLanguageCode)
  
    return (
      <div className="inline-block  text-left ">
        <div className='cursor-pointer md:mr-1'>
          <div
            className=" px-2 py-1.5 text-2xl md:text-base font-semibold md:ring-1 ring-slate-300 rounded-lg "
            onClick={() => changeLanguage(currentLanguageCode === 'en' ? 'es' : 'en')}
          >
            <span className={`flag-icon flag-icon-${currentLanguageCode === 'en' ? 'es' : 'us'} mr-2`} />
                {/* <p>  */}
                {currentLanguageCode === 'en' ? 'Español' : 'English'} 
                    {/* </p> */}
          </div>
        </div>
      </div>
    );
  }