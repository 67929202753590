const brokeText = [
    {
        accountName: "What is a Brokerage Account",
        secondText: "Unlike a Roth IRA, where you are taxed for early withdrawals, a brokerage account allows you to access your money at any time. There are no contribution or income limits, though your profits are subject to taxes.",
        stepOne: "1. Create a Fidelity Brokerage Account",
        syncs: " Finturo syncs with your Fidelity account and manages your investments for you, so you don't have to worry about the little details. Learn more about how Finturo invests for you",
        link: "https://digital.fidelity.com/prgw/digital/login/user-identity?intent=nur&",
        span: "here."
    },
    {
        accountName: "Que es una Cuenta de Coretaje",
        secondText: "A diferencia de una cuenta IRA Roth, donde paga impuestos por los retiros anticipados, una cuenta de corretaje le permite acceder a su dinero en cualquier momento. No hay límites de contribución o ingresos, aunque sus ganancias están sujetas a impuestos.",
        stepOne: "1. Crear una Cuenta de Coretaje con Fidelity",
        syncs: "Finturo se sincroniza con su cuenta de corretaje de Fidelity y administra sus inversiones por usted, para que no tenga que preocuparse por los pequeños detalles. Conoce más sobre cómo Finturo invierte por ti",
        link: "https://digital.fidelity.com/prgw/digital/login/user-identity?intent=nur&",
        span: "aquí."
    },
    {
        accountName: "What is a Roth IRA",
        secondText: "Unlike a Roth IRA, where you are taxed for early withdrawals, a brokerage account allows you to access your money at any time. There are no contribution or income limits, though your profits are subject to taxes.",
        stepOne: "1. Create a Fidelity Roth IRA",
        syncs: "Finturo syncs with your Fidelity account and manages your investments for you, so you don't have to worry about the little details. Learn more about how Finturo invests for you ",
        link: "https://digital.fidelity.com/prgw/digital/easy/aolanding/getstarted?accountType=rothIRA",
        span: "here."
    },
    {
        accountName: "¿Qué es un Roth IRA?",
        secondText: "A diferencia de un Roth IRA, donde se gravan los retiros tempranos, una cuenta de corretaje te permite acceder a tu dinero en cualquier momento. No hay límites de contribución o ingresos, aunque tus ganancias están sujetas a impuestos.",
        stepOne: "1. Crea una cuenta Roth IRA en Fidelity",
        syncs: "Finturo se sincroniza con tu cuenta Roth IRA en Fidelity y gestiona tus inversiones por ti, así que no tienes que preocuparte por los pequeños detalles. Obtén más información sobre cómo Finturo invierte para ti ",
        link: "https://digital.fidelity.com/prgw/digital/easy/aolanding/getstarted?accountType=rothIRA",
        span: "aquí."
    }
]


export default brokeText;
