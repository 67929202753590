import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";

import Value from "./ValueStatement";
import Testimonials from "./Testimonials";
import OldSignUp from "./OldSignUp";

import bar2 from './img/bar2.png';

// better for simpler things, no state requirements
// function HomeList() {
//     return "Welcome to Finanza"
// } 

// if you have states recommended you use this

const phoneGrid = {
  gridTemplateRows: "17% 18% 55% 10%"
}

const phoneStyling = {
  height: "95%",
  width: "98%",
  // other styles
};

const phoneStylingMobile = {
  height: "95%",
  width: "98%",
  // width: "100%",
  // other styles
};

export default function HomePage() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [show, setShow] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight)
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const isMobile = windowWidth <= 767;


  const popUp = {
    transform: 'translate(-50%, -50%)',
    height: '80vh',
    width: '90vw'
  }

  const filter = {
    filter: 'opacity(40%)'
  }

  const { t } = useTranslation()

  const handleClose = () => {
    // set show to false when the div wrapping the popup is clicked
    if (show) {
      setShow(false);
    }
  };


  return (

    <div>
      <Navbar></Navbar>
      {show && <div className="z-10 fixed left-2/4 top-2/4 bg-blue-300 rounded-3xl flex items-center justify-center cursor-pointer" style={popUp}>
        <div className="absolute -top-5 -right-5  cursor-pointer " onClick={() => setShow(!show)}>
          <ion-icon name="close" style={{ fontSize: '32px' }}></ion-icon>
        </div>
        <iframe className="w-full h-full rounded-3xl"
          src={t("link")}
          title="Youtube video">
        </iframe>
      </div>}

      {/* how do I justify center only when the screen exceeds the content size */}
      <div class="flex flex-col items-center  h-screen lg:h-full "
        style={{ ...show ? filter : null }} onClick={handleClose}>
        <div class="side-galaxy:col-span-2 side-galaxy:row-span-1 bg-white w-screen  pt-12 side-galaxy:pt-0 items-center" >
          {/* <iframe className="w-full h-full rounded-3xl" 
        src="fidelity.com"
        title="Youtube video">
          </iframe> */}
          {/* start of homescreen */}
          <div class="flex flex-col md:flex-row bg-white-500 lg:items-center lg:justify-center">
          {/* style={windowWidth <= 767 ? valueHeight : null} */}
            <div class="flex side md:w-4/6 items-center justify-center bg-white h-[38rem] lg:h-[60vh] md:max-h-[80vh] lg:max-h-[52rem] pt-2 md:pt-0 max-w-[60rem]"  >
              <Value />
            </div>

            <div class="flex md:w-2/6 items-center justify-center bg-white h-[38rem] lg:h-[60vh] md:max-h-[80vh] lg:max-h-[52rem] max-w-[45rem]" >

              <div class="-mt-2 -mb-2 mr-2 m-2 rounded-2xl bg-FinanzaOrange relative text-white shadow-2xl grid" style={{ ...isMobile ? phoneStylingMobile : phoneStyling, ...phoneGrid }}>

                <div className="flex justify-center  items-end w-full ">
                  <button type="button" className=" xl:top-14  bg-FinanzaBlue rounded-sm w-9/12 h-3/6 text-center cursor-pointer"
                    onClick={() => setShow(!show)}>
                    {t('video_btn')}</button>
                </div>

                <div className="flex justify-center items-center text-2xl">
                  <div className="mr-4 text-lg text-center ">
                    <p > {t('savings_btn')}
                    </p>
                    <p> $12,000</p>
                  </div>
                  <div className="ml-4 text-lg text-center">
                    <p >Finturo </p>
                    <p> $23,500 </p>
                  </div>

                </div>
                <div className="flex justify-center   ">
                  <img src={bar2} class=""></img>
                </div>
                <div class=" mb-4 flex justify-center items-center">
                  <p class="text-lg ">{t('Bar_Description')} </p>
                </div>

              </div>
            </div>

          </div>
        </div>
        {/* style={windowWidth >= 800 && windowHeight < 415 ? phoneSide : null} */}
        <div class="bg-white-500 w-full"
          
        >
          <div class="flex flex-col md:flex-row md:h-full lg:items-center lg:justify-center">
            <div class="flex md:w-2/5 items-center justify-center bg-white h-[32rem] lg:h-[32vh] lg:max-h-[52rem] md:max-h-[60vh] max-w-[60rem]">
              {windowWidth <= 768 ? (
                <OldSignUp />
              ) : (
                <Testimonials />
              )}
            </div>

            <div class="flex md:w-3/5 items-center justify-center h-[32rem] lg:h-[32vh] lg:max-h-[52rem] md:max-h-[60vh] max-w-[60rem] ">
              {windowWidth <= 768 ? (
                <Testimonials />
              ) : (
                < OldSignUp />
              )}
            </div>

          </div>

        </div>
      </div>
    </div>


  );
}