import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';


let my_data = [5, 3206 ,6358 ,10917 , 17423 , 26762, 41406];
let defstartVisble = true;

const NewGraph = ({ sldrstrtVal = 25, startVisble = true}) => {

    const headerTextColor = {
        color: '#75B570',
    };

   defstartVisble = startVisble;
   const [investment, setInvestment] = useState(sldrstrtVal);

   let first_data = my_data.map(function (element) { return Math.floor(element * (investment/25))})
   const [estimatedBalance, setEstimatedBalance] = useState(first_data[first_data.length-1]);

  // Data for the bar chart
  const [chartData, setChartData] = useState({
    labels: ['2023', '2030', '2035', '2040', '2045', '2050', '2055'],
    datasets: [
      {
        label: 'Estimated Future Value',
        data: first_data,
        backgroundColor: 'rgba(0, 200, 0, 0.9)',
        borderColor: 'rgba(0, 175, 0, 1)',
        // borderWidth: 0,
        // borderRadius: 40,
        fill: false,
        // barPercentage: 0.9,        // Adjust the bar width (closer together)
        // categoryPercentage: 1.0,  
        borderSkipped: false,
        pointRadius: (context) => {
          // Return different point radii for the last data point
          return context.dataIndex === context.dataset.data.length - 1 ? 10 : 3;
        },
        pointStyle: (context) => {
          // Return different point styles for the last data point
          return context.dataIndex === context.dataset.data.length - 1 ? 'circle' : 'circle';
        }
      },
    ],
  });

  // Chart options
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hide x-axis gridlines
        },
        ticks: {
            font: {
              weight: 'bold', // Make x-axis text bold
              size: 14,        // Make x-axis text larger
            },
          },
      },
      y: {
        beginAtZero: true,
        // maintainAspectRatio: false,
        position: 'right', 
        grid: {
          display: false, // Hide y-axis gridlines
        },
        ticks: {
            font: {
              weight: 'bold', // Make x-axis text bold
              size: 14,        // Make x-axis text larger
            },
          },
      },
    },
    plugins: {
        legend: {
          position: 'top',
          // display: true, // Hide the legend
          labels: {
            usePointStyle: true, // Use point style for legend items
            font: {
              weight: 'bold', // Make the legend text bold
              size: 13,
            }
          }
        },
      },
  };

  const handleSliderChange = (e) => {
    // inputed value from slider
    const newInvestment = parseInt(e.target.value);
    // const ratio = newInvestment/investment;
    // console.log("newInvestment",newInvestment, "Investment", investment, "Ratio", ratio)
    setInvestment(newInvestment);
    const ratio = newInvestment/25;
    // Update the data values in the chartData
    let updatedData = my_data;
    
    updatedData = updatedData.map(function (element) { return Math.floor(element * ratio)});
    setEstimatedBalance(updatedData[6])
    setChartData({
      ...chartData,
      datasets: [{ ...chartData.datasets[0], data: updatedData }],
    });
  };

  return (
    <div className="flex flex-col justify-center items-center px-6 md:p py-4 relative ">
        <h1 className=' text-lg text-center font-bold my-2'> 
                Your <span style={headerTextColor}>${investment} monthly </span> 
                can be <span style={headerTextColor}> ${estimatedBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })} </span>
                in <span style={headerTextColor}> 30 years </span>
            </h1>
     
      <div className="mt-4 w-full h-[15rem] ">
        {/* <canvas> */}
            <Line data={chartData} options={options}  />
        {/* </canvas> */}
       
      </div>
      <p className="font-bold mr-2 text-center text-lg mb-4 "> Monthly Investment: <span className='text-FinanzaGreen underline'>${investment}</span></p>
      <div className="w-full h-5 bg-gray-200 rounded-lg flex justify-center">
        <input
          type="range"
          min="25"
          max="1000"
          step="25"
          defaultValue={investment}
          onChange={handleSliderChange}
          className="w-full h-5 accent-FinanzaGreen bg-transparent"
          placeholder="Select an investment value"
          
        />
      </div>
      {defstartVisble && <p className="font-bold mr-2 text-center text-lg mb-4 "> Starting Amount: ${5}</p>}
    </div>
  );
};

export default NewGraph;