import React, { useState} from "react";

export default function Question({items}){

    const [clickedIndexes, setClickedIndexes] = useState([]);

    const handleDivClick = (index) => {
        if (clickedIndexes.includes(index)) {
          setClickedIndexes(clickedIndexes.filter((i) => i !== index));
        } else {
          setClickedIndexes([...clickedIndexes, index]);
        }
      };
    
    return(
      <div>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => handleDivClick(index)}
          className={`flex flex-col my-3 ${
            clickedIndexes.includes(index) ? 'border-2 border-FinanzaGreen shadow-md px-4 py-6 rounded-lg' : ''
          } duration-100`}
        >
          <div className='flex items-center'>
            <div className='flex-grow'>
              <h1 className='text-2xl pb-2'>{item.question}</h1>
            </div>
            <div
              className={`ml-2 pt-0.5 cursor-pointer ${
                clickedIndexes.includes(index) ? 'text-FinanzaGreen' : 'text-black'
              }`}
            >
              <ion-icon
                name={clickedIndexes.includes(index) ? 'chevron-up-outline' : 'chevron-down-outline'}
                size='large'
              ></ion-icon>
            </div>
          </div>

          <div
            className={`border-0 transition-opacity ${
              clickedIndexes.includes(index) ? 'opacity-100 visible' : 'opacity-0 hidden'
            } duration-500`}
          >
            <p className='text-base'>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
    );
}
