const milestones = [
    {
        name: "Fidelity",
        time: "~10 minutes",
        link: "goal",
        status: 1
      },
      {
        name: "Link Bank Account",
        time: "~5 minutes",
        link: "chooseAmount",
        status: 2
      },
      {
        name: "Funding your Future",
        time: "~10 minutes",
        link: "fundingYourFuture",
        status: 3
      },
]

export default milestones