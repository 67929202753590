import React, { Suspense, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth'
import NewGraph from '../NewGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faBuildingColumns, faChartLine, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

function capFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Homescreen = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setTimeout(() => setReady(true), 1000)
  }, [])


  const { firstName, monthlyContribution, FXAIX, SPAXX, startingAmount, startDate } = useAuth()
  const capFirstName = capFirstLetter(firstName)
  // ready ? <SuspenseTrigger/> :
  const monthsDiff = () => {

    const today = new Date();
    const todayString = today.toISOString();
    let [curYear, curMonth, curDay] = todayString.split('T')[0].split('-').map(Number);
    let [startYear, startMonth, startDay] = startDate.split('T')[0].split('-').map(Number);
 
    if (curDay < startDay){
      curMonth = (curMonth === 1) ? 12 : (curMonth - 1);
      curYear = (curMonth === 12) ? (curYear - 1) : curYear
    }

    return ((curYear - startYear) * 12 + curMonth - startMonth) + 1;
  };
  
  const { t } = useTranslation();
  /*
    total cash taken from client's account;
    not the same as cost basis since it doesn't include dividends
    totInvested updated everytime someone's changes their investment plan
  */
  // 
  const totInvested = startingAmount + monthsDiff() * monthlyContribution;
  const invValue = Number(FXAIX + SPAXX).toFixed(2);
  return (
    <div className='flex flex-col items-center   text-center mx-2'>
      <div className='bg-FinanzaGreen pb-4 pt-6 mt-12 rounded-xl shadow-md w-full mx-2'>
        <p className='text-lg font-semibold'>
        {t('dashHi')} {capFirstName}, {t('dashWelcome')}.
        </p>
      </div>
      <div className='max-w-screen shadow-md rounded-xl border-t-2 mt-0.5 border-slate-250 '>
        <NewGraph  sldrstrtVal={monthlyContribution} startVisble={false} />
      </div>

      {/* Account info */}
      {/* <div className='py-6 rounded-xl shadow-md w-full border-t-2 border-slate-250 mt-1 font-semibold text-left'> */}
        <div className='rounded-xl w-full border py-6 my-1 shadow-md font-semibold'>
        <div className='flex pl-4'>
          <div className='bg-slate-200 h-8 w-8 rounded-full mr-2 flex items-center justify-center'>
            <FontAwesomeIcon icon={faMoneyBill} />
          </div>
          <p className='text-2xl'>{t('dashInvested')} <span className='text-FinanzaGreen font-normal'>${totInvested } </span> </p>
        </div>
        </div>
        <div className='py-6 pl-4 border w-full rounded-xl mb-1 shadow-md'>
          <div className='flex'>
            <div className='bg-slate-200 h-8 w-8 rounded-full mr-2 flex items-center justify-center'>
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <p className='text-2xl font-semibold '>{t('dashWorth')}</p>
          </div>

          <div className='flex w-full justify-center items-center gap-x-2 '>
            <div className='text-3xl text-FinanzaGreenDarker '>
              <p> ${invValue}</p>
            </div>
            <div className='mt-[0.25rem] bg-green-200 px-1  rounded-xl flex justify-center items-center gap-x-0.5 h-6 border-2 border-green-800 '>
              <FontAwesomeIcon icon={faArrowUp} />
              <p> {Number((invValue * 1.0 - totInvested)/(totInvested) * 100.0).toFixed(1)}%</p>
            </div>
          </div>
        </div>
        <div className='pl-4 flex border py-6 rounded-xl w-full my-1 shadow-md'>
        <div className='bg-slate-200 h-8 w-8 rounded-full mr-2 flex items-center justify-center'>
              <FontAwesomeIcon icon={faBuildingColumns} />
        </div>
        <p className=' text-2xl underline text-red-700 cursor-pointer font-semibold'>{t('dashBank')}</p>

        </div>
      {/* </div> */}
     
      <div className='py-6 rounded-xl shadow-md w-full border-t-2 border-slate-250 mb-4'>
        <p>{t('dashPlan')}</p>
        <div className='flex items-center justify-center pt-2 '>
          <p className='text-3xl text-FinanzaGreen mr-2'> ${monthlyContribution}</p>
          <p> {t('dashMonthly')} </p>
        </div>
      </div>
    </div>
  )
}

export default Homescreen