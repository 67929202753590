import React, { useEffect, useState } from 'react';

import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Instructions from './Instructions'
import instruction from './instruction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { useUpdateUserMutation } from "../../../../features/users/usersApiSlice"
// /../../users/usersApiSlice
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
// ../../../hooks/useAuth

Chart.register(CategoryScale);

const BankAccount = () => {    
    const signUpStatus = 3;

    const { id } = useAuth()
    const navigate = useNavigate()
    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const onBankLinkedClicked = async (e) => {
        const submitButton = document.querySelector('#submit');
        const form = document.querySelector('#form');
        e.preventDefault()
        // e.stopPropagation();
        submitButton.disabled = true;
        submitButton.innerHTML = "Loading..."

        await updateUser({ id, signUpStatus })
        navigate('/dash', { state: { updatedSignUpStatus: signUpStatus } }); // Pass updatedSignUpStatus  
    }
    const content = (
        <>
        

<div className='pb-10'>
                <>
                <div className='px-10 md:px-40 flex flex-col pt-40 md:pt-0'>
                    <div className="mt-10">
                        <p className='text-center text-gray-600 text-2xl font-bold '> Follow these instructions to connect your <span className='text-FinanzaGreen'> bank</span> to <span className='text-FinanzaGreen'> fidelity</span></p>  
                    </div>
                   
                    {/* <h1 className='text-center m-4 text-4xl text-green-800 font-extrabold'> 1. Login to your Fidelity Account </h1> */}

                    <div className=' flex flex-col items-center rounded'>                        
                        <div className="my-9 border-gray-300 rounded-lg flex flex-col w-full items-center ">
                            <Instructions items={instruction}/>
                        </div>
                    
                       
                        <div className="flex justify-center items-center">
                            <button 
                            className="mx-2 mb-4 px-4 py-2 w-64 text-white bg-FinanzaGreen hover:bg-FinanzaGreenDarker py-2 px-4 rounded-md border border-black text-lg font-bold" 
                            type='submit' 
                            id="submit" 
                            onClick={onBankLinkedClicked}
                            >
                                My Bank is Connected
                            </button>
                        </div>
                    </div>
                </div>
                </>
        </div>
        </>
       )
      return content
}
export default BankAccount
