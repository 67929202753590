import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPenToSquare, faArrowLeft, faSave, faInfoCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"
import '../../EditUserForm.css'

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/


const EditUserForm = ({ user }) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    const [finturoUsername, setUsername] = useState(user.finturoUsername)

    const [accountEmail, setAccountEmail] = useState(user.accountEmail)
    const [signUpStatus, setsignUpStatus] = useState(user.signUpStatus)
    const [firstName, setfirstName] = useState(user.firstName)
    const [lastName, setlastName] = useState(user.lastName)
    const [dateOfBirth, setdateOfBirth] = useState(user.dateOfBirth)
    const [phoneNumber, setphoneNumber] = useState(user.phoneNumber)
    const [createdAt, setcreatedAt] = useState(user.createdAt)

    const [inEditMode, setInEditMode] = useState(false);
    const [madeChanges, setmadeChanges] = useState(false);

    const [usernameChanged, setusernameChanged] = useState(false);
    const [passwordChanged, setpasswordChanged] = useState(false);
    const [phoneNumberChanged, setphoneNumberChanged] = useState(false);
    const [accountEmailChanged, setaccountEmailChanged] = useState(false);
    const [dateOfBirthChanged, setdateOfBirthChanged] = useState(false);
    const [signUpStatusChanged, setsignUpStatusChanged] = useState(false);




    const [validUsername, setValidUsername] = useState(false)
    const [finturoPassword, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(user.roles)
    const [active, setActive] = useState(user.active)

    useEffect(() => {
        setValidUsername(USER_REGEX.test(finturoUsername))
    }, [finturoUsername])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(finturoPassword))
    }, [finturoPassword])

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess || isDelSuccess) {
            setUsername('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onUsernameChanged = e => {
        setUsername(e.target.value)
        setusernameChanged(true)
        setmadeChanges(true)
    }
    const onPasswordChanged = e => {
        setPassword(e.target.value)
        setpasswordChanged(true)
        setmadeChanges(true)
    }

    const onPhoneNumberChanged = e => {
        setphoneNumber(e.target.value) //TODO
        setphoneNumberChanged(true)
        setmadeChanges(true)
    }

    const onAccountEmailChanged = e => {
        setAccountEmail(e.target.value) //TODO
        setaccountEmailChanged(true)
        setmadeChanges(true)
    }

    const ondateOfBirthChanged = e => {
        setdateOfBirth(e.target.value) //TODO
        setdateOfBirthChanged(true)
        setmadeChanges(true)
    }

    const onsignUpStatusChanged = e => {
        setsignUpStatus(e.target.value) //TODO
        setsignUpStatusChanged(true)
        setmadeChanges(true)
    }

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onActiveChanged = () => setActive(prev => !prev)

    const onSaveUserClicked = async (e) => {
        if (finturoPassword) {
            await updateUser({ id: user.id, finturoUsername, finturoPassword, signUpStatus, phoneNumber, accountEmail, dateOfBirth, roles, active })
        } else {
            await updateUser({ id: user.id, finturoUsername, signUpStatus, phoneNumber, accountEmail, dateOfBirth, roles, active })
        }
    }

    const onDeleteUserClicked = async () => {
        // const userConfirmed = window.confirm("Are you sure you want to delete this account?");
        // if (!userConfirmed) {
        //     return;
        // }
        
        // await deleteUser({ id: user.id })


        // Display the custom modal when delete button is clicked
        const modal = document.getElementById('custom-modal');
        modal.style.display = 'block';

        // Handle modal interactions
        const confirmDeleteButton = document.getElementById('confirm-delete-button');
        const cancelDeleteButton = document.getElementById('cancel-delete-button');

        confirmDeleteButton.addEventListener('click', async () => {
            modal.style.display = 'none';
            await deleteUser({ id: user.id });
        });

        cancelDeleteButton.addEventListener('click', () => {
            modal.style.display = 'none';
        });
    } 

    const onEditClicked = () => {
        if (madeChanges && inEditMode) {
            const userConfirmed = window.confirm("You've made unsaved changes. Are you sure you want to exit?");
            if (!userConfirmed) {
                // If the user wants to keep editing, exit the function
                return;
            }
        }
        setInEditMode(prevMode => !prevMode);
    };

    const getStatusIcon = (status) => {
        switch (status) {
          case 1:
            return <FontAwesomeIcon icon={faCheckCircle} className="text-yellow-500" />;
          case 2:
            return <FontAwesomeIcon icon={faCheckCircle} className="text-yellow-500" />;
          case 3:
            return <FontAwesomeIcon icon={faCheckCircle} className="text-yellow-500" />;
          case 4:
            return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />;
          default:
            return null;
        }
      };

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    let canSave
    if (finturoPassword) {
        canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading
    } else {
        canSave = [roles.length, validUsername].every(Boolean) && !isLoading
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validUserClass = !validUsername ? 'form__input--incomplete' : ''
    const validPwdClass = finturoPassword && !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <div id="custom-modal" className="modal">
                <div className="modal-content">
                    <h1 className="text-center mb-3 text-xl">Delete User</h1>
                    <p className="text-center mb-3">Are you sure you want to delete this account?</p>
                    <div className="flex justify-between text-center">
                        <button 
                        id="cancel-delete-button"
                        className="bg-greyCustom hover:bg-greyCustomDarker text-white py-1 px-8 rounded-full focus:outline-none"
                        >
                            Cancel
                        </button>
                        <button 
                        id="confirm-delete-button"
                        className="bg-redCustom hover:bg-redCustomDarker text-white py-1 px-8 rounded-full focus:outline-none"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row" style={{ marginLeft: '20px', marginTop: '20px' }}>
                    <h2 className="text-4xl font-bold mb-4 font-sans"> 
                    {firstName} {lastName} 
                    {inEditMode ? (
                        <button
                            className={`icon-button table__button`}
                            onClick={onEditClicked}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '20px' }}/>
                        </button> 
                    ) : (
                        <button
                            className="icon-button table__button"
                            title="edit"
                            onClick={onEditClicked}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} style={{ fontSize: '20px' }}/>
                        </button> 
                    )}  
                    </h2>
                </div>

                <hr className="my-4 border-black" />

                {inEditMode ? (
                
                <div style={{ marginLeft: '40px', marginBottom: '40px'}}>
                    <h2 className="text-gray-800 mb-2">
                        <label className="form__label text-gray-500 mb-8" htmlFor="username">
                            Username: <span className="nowrap"></span>
                        </label>
                        <input
                            className={`form__input ${validUserClass}`}
                            id="username"
                            name="username"
                            type="text"
                            autoComplete="off"
                            value={finturoUsername}
                            onChange={onUsernameChanged}
                        
                            style={{ border: !usernameChanged ? '1px solid #000000' : validUsername ? '2px solid #75B570' : '2px solid #b31429' }}
                        />
                        {usernameChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                        <label className="form__label text-gray-500 mb-8" htmlFor="password">
                            Password: <span className="nowrap"></span> <span className="nowrap"></span></label>
                        <input
                            className={`form__input ${validPwdClass}`}
                            id="password"
                            name="password"
                            type="password"
                            value={finturoPassword}
                            onChange={onPasswordChanged}
                            style={{ border: !passwordChanged ? '1px solid #000000' : validPassword ? '2px solid #75B570' : '2px solid #b31429' }}

                        />
                        {passwordChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <label className="form__label text-gray-500 mb-8" htmlFor="signUpStatus">
                            Sign-up Status:
                        </label>
                        <input
                            className={`form__input`}
                            id="signUpStatus"
                            name="signUpStatus"
                            type="text"
                            value={signUpStatus}
                            onChange={onsignUpStatusChanged}
                            style={{ border: !signUpStatusChanged ? '1px solid #000000' : '2px solid #75B570' }}
                        />
                        {signUpStatusChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <h2 className="text-lg mt-8 mb-2 font-bold">PERSONAL INFO</h2>

                    <h2 className="text-gray-800 mb-2">
                        <label className="form__label text-gray-500 mb-8" htmlFor="phoneNumber">
                            Phone Number:
                        </label>
                        <input
                            className={`form__input`}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            value={phoneNumber}
                            onChange={onPhoneNumberChanged}
                            style={{ border: !phoneNumberChanged ? '1px solid #000000' : '2px solid #75B570' }}
                        />
                        {phoneNumberChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <label className="form__label text-gray-500 mb-8" htmlFor="accountEmail">
                            Account Email:
                        </label>
                        <input
                            className={`form__input`}
                            id="accountEmail"
                            name="accountEmail"
                            type="text"
                            value={accountEmail}
                            onChange={onAccountEmailChanged}
                            style={{ border: !accountEmailChanged ? '1px solid #000000' : '2px solid #75B570' }}
                        />
                        {accountEmailChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <label className="form__label text-gray-500 mb-8" htmlFor="dateOfBirth">
                            Date of Birth:
                        </label>
                        <input
                            className={`form__input`}
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="text"
                            value={dateOfBirth}
                            onChange={ondateOfBirthChanged}
                            style={{ border: !dateOfBirthChanged ? '1px solid #000000' : '2px solid #75B570' }}
                        />
                        {dateOfBirthChanged && (
                            <span className="text-gray-500 ml-2 text-sm italic">unsaved changes</span>
                        )}
                    </h2>

                    <p className="text-lg mt-8 mb-2 font-bold ">ACCOUNT INFO</p>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Created: </text> 
                        {createdAt}
                    </h2>

                    {/* <button className="bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none">
                view users
              </button> */}
                    {inEditMode && (
                        <button
                                className="bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-1 px-2 rounded-full focus:outline-none"
                                title="Save"
                                onClick={onSaveUserClicked}
                                disabled={!canSave && !madeChanges}
                            >
                                {/* <FontAwesomeIcon icon={faSave} /> */}
                                save changes
                        </button>
                    )}
                </div>
                ) : (
                    <div style={{ marginLeft: '40px', marginBottom: '40px'}}>
                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Username: </text> 
                    {finturoUsername}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Password: </text> 
                        **********
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Sign-up Status: </text> 
                        {getStatusIcon(signUpStatus)}
                    </h2>

                    <p className="text-lg mt-8 mb-2 font-bold ">PERSONAL INFO</p>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Phone Number: </text> 
                        {phoneNumber}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Account Email: </text> 
                        {accountEmail}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Date of Birth: </text> 
                        {dateOfBirth}
                    </h2>

                    <p className="text-lg mt-8 mb-2 font-bold ">ACCOUNT INFO</p>

                    <h2 className="text-gray-800 mb-2">
                    <text className="text-gray-500 mb-8"> Created: </text> 
                        {createdAt}
                    </h2>

                    <h2 className="text-gray-800 mb-2">
                    <text className="mb-8"> Delete Account: </text> 
                        <button
                            className="icon-button text-red-700"
                            title="Delete"
                            onClick={onDeleteUserClicked}
                        >
                            <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: '15px' }} />
                        </button>
                    </h2>

                    {/* <button className="bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-2 px-4 rounded-full focus:outline-none">
                view users
              </button> */}
                    {inEditMode && (
                        <button
                            className={`bg-FinanzaGreen hover:bg-FinanzaGreenDarker text-white py-1 px-2 rounded-full focus:outline-none ${
                                (!canSave || !madeChanges) ? 'hover:bg-FinanzaGreen' : ''
                            }`}
                            title="save changes"
                            onClick={onSaveUserClicked}
                            disabled={!canSave || !madeChanges}
                            >
                            save changes
                        </button>
                    )}
                </div>
                )}

            </form>
        </>
    )

    return content
}
export default EditUserForm