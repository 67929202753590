// Who we are 
import React, {useState, useEffect} from "react";
import { useTranslation, Trans } from "react-i18next";
import PhoneMock1 from './img/PhoneMock1.png'
import SpanishPhone from './img/SpanishPhone.png'

const greenCircle = {
    backgroundImage : "radial-gradient(circle, #FFFFFF 0%, #4CAF50 70%, transparent 50%)"
}


export default function Value(){
     const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight)
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }, []);
    const { t } = useTranslation()

    const phoneHeight = {
      height: '12rem',
    }

    return(
        <div class="relative flex flex-col xs:flex-row -mt-2 -mb-2 rounded-2xl bg-white text-black shadow-2xl relative flex justify-center items-center overflow-hidden" style={{height : "95%", width: "98%"}}>
  
        <div class="order-2 xs:order-1 w-full xs:w-auto flex justify-center">
             <img class="bottom-0 left-1 h-5/6 max-h-80 max-w-none" src={localStorage.getItem('i18nextLng')==="en" ? PhoneMock1 : SpanishPhone} style={windowHeight < 570 ? phoneHeight : null}></img>
        </div>
  
  <div class="text-center xs:text-start order-1 xs:order-2 h-auto w-full xs:w-1/2 mb-6 xs:mb-0 xs:pl-6">
    <h1 class="text-2xl md:text-4xl iphone-height:text-5xl xs:text-5xl sm:text-6xl md:text-7xl font-Walsh1 font-bold side-phone:text-4xl">
      <Trans i18nKey="headline">
        Grow yourrr <span className='text-FinanzaGreen'>{t('dif_color_word')}</span> today
      </Trans>
    </h1>
    <p class="mx-auto max-w-sm text-4xl xs:text-3xl text-gray-800 pt-6 font-Walsh2">{t('value_sub_text')}</p>        
  </div>
</div>

    );

}