import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Bar } from 'react-chartjs-2';

import GraphComponent from '../../graphComponent';
import NewGraph from '../../NewGraph';
import { useUpdateUserMutation } from '../../../features/users/usersApiSlice';
import useAuth from '../../../hooks/useAuth';

let my_data = [5, 3206, 6358, 10917, 17423, 26762, 41406];

function BankTrans() {

  const {monthlyContribution} = useAuth()

  const [updateUser, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useUpdateUserMutation()

  const onSaveUserClicked = async (e) => {
      await updateUser({ monthlyContribution })
  }

  const headerTextColor = {
    color: '#75B570',
  };

  const [investment, setInvestment] = useState(25);

  const [estimatedBalance, setEstimatedBalance] = useState(my_data[my_data.length - 1]);

  // Data for the bar chart
  const [chartData, setChartData] = useState({
    labels: ['Today', '2030', '2035', '2040', '2045', '2050', '2055'],
    datasets: [
      {
        label: 'Sample Data',
        data: my_data,
        backgroundColor: 'rgba(0, 189, 0, 0.9)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 0,
        borderRadius: 40,
        // barPercentage: 0.9,        // Adjust the bar width (closer together)
        categoryPercentage: 1.0,
        borderSkipped: false,
      },
    ],
  });

  // Chart options
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hide x-axis gridlines
        },
        ticks: {
          font: {
            weight: 'bold', // Make x-axis text bold
            size: 14,        // Make x-axis text larger
          },
        },
      },
      y: {
        beginAtZero: true,
        // maintainAspectRatio: false,
        position: 'right',
        grid: {
          display: false, // Hide y-axis gridlines
        },
        ticks: {
          font: {
            weight: 'bold', // Make x-axis text bold
            size: 14,        // Make x-axis text larger
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  const handleSliderChange = (e) => {

    const newInvestment = parseInt(e.target.value);
    const ratio = newInvestment / investment;
    setInvestment(newInvestment);
    // Update the data values in the chartData
    let updatedData = [...chartData.datasets[0].data];
    updatedData = updatedData.map(function (element) { return Math.floor(element * ratio) });
    setEstimatedBalance(updatedData[6])
    setChartData({
      ...chartData,
      datasets: [{ ...chartData.datasets[0], data: updatedData }],
    });
  };

  return (
    <div className='px-2'>
      <div className=' text-center py-8 pt-28 md:pt-0'>
        <h1 className='text-2xl font-bold'>Adjust the slider to help you figure out a starting amount</h1>
      </div>

      <div className='border-2 rounded-lg border-FinanzaGreen '>
        <div className="flex flex-col justify-center items-center px-6 md:p py-4 relative ">
          <h1 className=' text-lg text-center font-bold my-2'>
            Your <span style={headerTextColor}>${investment} monthly </span>
            can be <span style={headerTextColor}> ${estimatedBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })} </span>
            in <span style={headerTextColor}> 30 years </span>
          </h1>

          <div className="mt-4 w-full h-[15rem] ">
            <Bar data={chartData} options={options} />
          </div>
          <p className="font-bold mr-2 text-center text-lg mb-4 "> Monthly Investment: <span className='text-FinanzaGreen underline'>${investment}</span></p>
          <div className="w-full h-5 bg-gray-200 rounded-lg flex justify-center">
            <input
              type="range"
              min="25"
              max="1000"
              step="25"
              defaultValue={investment}
              onChange={handleSliderChange}
              className="w-full h-5 accent-FinanzaGreen bg-transparent"
              placeholder="Select an investment value"

            />
          </div>
          <p className="font-bold mr-2 text-center text-lg mb-4"> Starting Amount: ${5}</p>
        </div>
      </div>
      <div className='flex justify-center '>
        <Link to='/dash/connectBank'>
          <button className='rounded-lg bg-FinanzaGreen px-4 py-2 font-bold text-lg text-white'>
            Continue
          </button>
        </Link>
      </div>
    </div>
  );
}

export default BankTrans