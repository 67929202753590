import React, { useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";

import '../../index.css'


export default function Testimonial({ items 
    ,autoSlideInterval=5000}){
    
      const [counter, setCounter] = useState(1);
      const [pause, setPause] = useState(false);
      const content = items;
    
      const handleNext = () => {
        if (counter !== content.length) {
          setCounter(counter + 1);
        } else {
          setCounter(1);
        }
      };
    
      const handlePre = () => {
        if (counter !== 1) {
          setCounter(counter - 1);
        } else {
          setCounter(content.length);
        }
      };
    
      const handlePage = (page) => {
        setCounter(page);
      };
    
      const handleMouse = () => {
        setPause(!pause);
      };
    
      useEffect(() => {
        let interval = setInterval(() => {
          if (!pause) {
            handleNext();
          } else {
            clearInterval(interval);
          }
        }, autoSlideInterval);
        return () => clearInterval(interval);
      });
    
        return (
        <div class="grid grid-rows-2 h-full rounded-2xl" style={{gridTemplateRows: "65% 35%"}}>
            <div class=" text-black font-Walsh2 relative flex justify-center ">
            <div className="text-center absolute top-0 h-4 w-full" >
                    {content.map((item, index) => (
                    <span
                        key={index}
                        className={counter - 1 === index ? " dot cursor-pointer h-1  opacity-100 inline-flex mr-1 active bg-white" : " dot cursor-pointer h-1  opacity-50 inline-flex mr-1"}
                        onClick={() => handlePage(index + 1)}
                        style={{width: "30%"}}
                    />
                    ))}
                </div>
                <div class="absolute bg-white w-8/12 h-5/6 max-w-9/12 text-left left-1/4 top-7 rounded-2xl px-2 flex justify-center items-center">
                    {content.map((item, index) => (
                        
                        <p className={counter - 1 === index ? "text-md lg:text-xl pl-1.5 show" : "hidden"}
                        key={index}> {item.statement} </p>
                    ))}
                    {/* <p class="text-xl pl-1.5">I was so hesitant to even think about my wealth until Finanza made it easy to get started</p> */}
                    
                </div>
            </div>
            <div class="relative">
                <div class="bg-white h-3/4 w-1/4 overflow-hidden relative rounded-2xl left-4 top-2.5 md:text-sm">
                {/* style={{transform: `translateX(-${curr * 100}%)`}} */}
                    {/* <div class="flex transition-opacity ease-out opacity-0 duration-500" >{slides}</div> */}
                    <div
                        className="slide "
                        onMouseEnter={handleMouse}
                        onMouseLeave={handleMouse}
                    >
                        {content.map((item, index) => (
                        <div
                            className={counter - 1 === index ? "absolute w-full h-full show flex justify-center items-center overflow-hidden" : "hidden"}
                            key={index}
                        >
                            <img src={item.link} alt="slide" />
                        </div>
                        ))}
                    </div>
                </div>
                <div
                        className="absolute w-1/4 h-3/4 left-44 top-2.5"
                        onMouseEnter={handleMouse}
                        onMouseLeave={handleMouse}
                    >
                        {content.map((item, index) => (
                        <div
                            className={counter - 1 === index ? "show " : "hidden"}
                            key={index}
                        >
                            {/* <img src={item.link} alt="slide" /> */}
                            <h2 className="text-md font-bold">{item.name}</h2>
                            <p className='text-sm'>{item.bio}</p>
                        </div>
                        ))}
                        <div className=' h-1.5 w-20 mt-1.5 bg-gray-200 z-20 rounded-none opacity-70'></div>
                </div>
    
               
            </div>
        </div>
      )

    }
